<template>

    <v-container>

        <h1 class="mt-8">{{ $t('Welcome') }} {{ currentUser.displayName() }}</h1>

        <div class="d-flex flex-wrap justify-space-between align-stretch">

            <v-card
            v-for="menu in activeData"
            :key="menu.id" 
            :disabled="menu.disabled"
            class="text-center ma-4 pa-4 flex-grow-1" width="150" :to="menu.to">

                <v-icon size="40">{{ menu.icon }}</v-icon><br>
                
                <div class="pt-4">
                    
                    <b>
                        {{ menu.name }}
                    </b>
                    
                </div>

            </v-card>

        </div>
        
    </v-container>

</template>

<script>
    
import { inject, ref, onMounted, computed } from '@vue/composition-api' ;

export default {
    
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentUser = inject('currentUser') ;
        const currentWebsite = inject('currentWebsite') ;

        const menuData = ref([
            {
                id: 1,
                name: ctx.root.$t('Pages'),
                icon: 'mdi-file-document-multiple-outline',
                to: '/pages',
                disabled:  false,
                multisite: false
            },
            {
                id: 2,
                name: ctx.root.$t('News'),
                icon: 'mdi-file-document-multiple-outline',
                to: '/news',
                disabled:  false,
                multisite: false
            },
            {
                id: 3,
                name: ctx.root.$t('Menus'),
                icon: 'mdi-credit-card-outline',
                to: '/menus',
                disabled:  false,
                multisite: false
            },
            {
                id: 4,
                name: ctx.root.$t('Accommodations'),
                icon: 'mdi-bed-outline',
                to: '/accommodations',
                disabled: false,
                multisite: false
            },
            // {
            //     id: 5,
            //     name: ctx.root.$t('Administrations'),
            //     icon: 'mdi-home-city-outline',
            //     to: '/administrations',
            //     disabled: false
            // },
            {
                id: 200,
                name: ctx.root.$t('Settings'),
                icon: 'mdi-cog-outline',
                to: '/settings',
                disabled: false,
                multisite: false
            }

        ]) ;

        onMounted(() => {
            currentWebsite.setTitle(ctx.root.$t('Dashboard'));
        });

        const activeData = computed(() => {

            let menus = [] ;

            menuData.value.forEach((menu) => {

                let valid = true ; 

                if(
                    menu.multisite == true &&
                    (
                        currentWebsite.state.data.multisite != true || 
                        currentWebsite.state.data.multisite == null
                    )
                ){
                    // menus.push(menu);
                    valid = false ;                     
                }

                if(valid){
                    menus.push(menu) ;
                }

            });

            return menus ; 
            
        });
        
        return {
            app,
            menuData,
            activeData,
            currentUser
        }
    
    },

};

</script>